import React, { useContext, useState } from 'react';
import { transformIframes } from '../../../utils/utils';
import styles from './styles/ChoicesBody.module.css';
import HintModal from './HintModal';
import { useScenario } from '../../../context/ScenarioContext';

const ChoicesBody = ({ choices, setCurrentSituationId, isScenarioLogged, preview }) => {
    const [showHintModal, setShowHintModal] = useState(false);
    const [hintText, setHintText] = useState('');
    const { addDecisionToLog } = useScenario();


    const handleClickChoice = async (choice) => {
        if (isScenarioLogged && !preview) {
            await addDecisionToLog({ 
                type: 'choice', 
                id: choice._id, 
                title: choice.title 
            });
        }
        if (!choice.hasHint) {
            setCurrentSituationId(choice.nextSituation._id);
            console.log("choice.nextSituation._id: ", choice.nextSituation._id);
        } else if (choice.hasHint) {
            setHintText(choice.hint);
            setShowHintModal(true);
        }
    };

    const closeModal = () => {
        setShowHintModal(false);
        setHintText('');
    };

    return (
        <>
            <div className={styles.container}>
                {choices.map((choice) => (
                    <div
                        key={choice._id}
                        className={styles.choice}
                        onClick={() => handleClickChoice(choice)}
                        dangerouslySetInnerHTML={{ __html: transformIframes(choice.text) }}
                    />
                ))}
            </div>

            {showHintModal && (
                <HintModal hintText={hintText} closeModal={closeModal} />
            )}
        </>
    );
};

export default ChoicesBody;

import React, { useState } from 'react';
import styles from './styles/CreateSidebar.module.css';
import { FaShapes, FaIndent, FaSortNumericDown } from 'react-icons/fa';
import CreateSituation from './CreateSituation';
import SituationsAndGroups from './SituationsAndGroups';
import SetOrder from './SetOrder';

const CreateSidebar = () => {
    const [activePanel, setActivePanel] = useState(null); // 'createSituation' or 'situationsList'
    const [isFullyExpanded, setIsFullyExpanded] = useState(false); // Track when expansion is complete

    const toggleCreateSituation = () => {
        setActivePanel(prev => (prev === 'createSituation' ? null : 'createSituation'));
    };

    const toggleSituationsList = () => {
        setActivePanel(prev => (prev === 'situationsList' ? null : 'situationsList'));
    };
    
    const toggleSetOrder = () => {
        setActivePanel(prev => (prev === 'setOrder' ? null : 'setOrder'));
    };
    // Handle when the transition ends
    const handleTransitionEnd = () => {
        if (activePanel) {
            setIsFullyExpanded(true); // Panel is fully expanded, render content
        } else {
            setIsFullyExpanded(false); // Reset when collapsing
        }
    };

    return (
        <div className={styles.container}>
            {/* Narrow Sidebar with Icons */}
            <div className={styles.iconSidebar}>
                {/* Existing Icons */}
                <div
                    className={`${styles.iconItem} ${activePanel === 'createSituation' ? styles.activeIcon : ''}`}
                    onClick={toggleCreateSituation}
                    data-tooltip-id="tooltip"
                    data-tooltip-content="Create Situation"
                >
                    <FaShapes />
                </div>
                <div
                    className={`${styles.iconItem} ${activePanel === 'situationsList' ? styles.activeIcon : ''}`}
                    onClick={toggleSituationsList}
                    data-tooltip-id="tooltip"
                    data-tooltip-content="Situations and Groups"
                >
                    <FaIndent />
                </div>
                {/* New Icon for SetOrder */}
                <div
                    className={`${styles.iconItem} ${activePanel === 'setOrder' ? styles.activeIcon : ''}`}
                    onClick={toggleSetOrder}
                    data-tooltip-id="tooltip"
                    data-tooltip-content="Set Order"
                >
                    <FaSortNumericDown />
                </div>
            </div>
            {/* Expanded Panel */}
            <div
                className={`${styles.expandedPanel} ${activePanel ? styles.activePanel : ''}`}
                onTransitionEnd={handleTransitionEnd}
            >
                {activePanel === 'createSituation' && <CreateSituation />}
                {activePanel === 'situationsList' && isFullyExpanded && <SituationsAndGroups />}
                {activePanel === 'setOrder' && isFullyExpanded && <SetOrder closePanel={() => setActivePanel(null)} />}
            </div>
        </div>
    );    
};

export default CreateSidebar;

import React, { useEffect, useState } from 'react';
import styles from './styles/ScenarioCarousels.module.css';
import axios from 'axios';
import ScenarioCarousel from './ScenarioCarousel';
import TabsBar from './TabsBar';

const ScenarioCarousels = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [scenarios, setScenarios] = useState([]);
  const [activeTab, setActiveTab] = useState('Featured');
  const [activeSubTab, setActiveSubTab] = useState('');

  useEffect(() => {
    const fetchScenarios = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL_LOCAL}/api/scenarios/public`);
        setScenarios(response.data);
      } catch (error) {
        console.error(`Error: ${error}`);
      } finally {
        setIsLoading(false);
      }
    };
    fetchScenarios();
  }, []);


  const featuredTitles = [
    'test scenario please ignore',
    'optimized steps to acing sat reading & writing',
    'rate: guide to compare',
    'decoding the black-scholes model',
    'SAT/ACT Grammar Part 1',
    'diagnostic test - ap physics c: mechanics',
    'middle school physics - about',
    'word problem challenge l3-l4',
    'grammar: perfect tenses',
    'english sentences to math equations',
    'author\'s perspective',
    'author\'s tone',
    'figurative language',
    'allusion',
    'text structures',
    'grammar: subject-verb agreement (full copy)',
    'grammar: continuous tenses',
    'grammar: simple tenses',
  ];

  const filteredFeaturedScenarios = scenarios
    .filter((scenario) =>
      featuredTitles.some(
        (title) => title.toLowerCase() === scenario.title.toLowerCase()
      )
    )
    .sort((a, b) => {
      const indexA = featuredTitles.findIndex(
        (title) => title.toLowerCase() === a.title.toLowerCase()
      );
      const indexB = featuredTitles.findIndex(
        (title) => title.toLowerCase() === b.title.toLowerCase()
      );
      return indexA - indexB;
    });

  const filteredEducationScenarios = scenarios.filter((scenario) => {
    const lowerCaseTags = scenario.tags.map((tag) => tag.toLowerCase());
    return lowerCaseTags.includes('education');
  });

  const filteredEntertainmentScenarios = scenarios.filter((scenario) => {
    const lowerCaseTags = scenario.tags.map((tag) => tag.toLowerCase());
    return lowerCaseTags.includes('entertainment');
  });

  const recentlyCreatedScenarios = [...scenarios]
    .sort((a, b) => new Date(b.publishedAt) - new Date(a.publishedAt))
    .slice(0, 20);

  const recentlyUpdatedScenarios = [...scenarios]
    .sort((a, b) => new Date(b.lastUpdated) - new Date(a.lastUpdated))
    .slice(0, 20);

  const popularScenarios = [...scenarios]
    .sort((a, b) => b.views - a.views)
    .slice(0, 20);

  const mapSubTabToTag = (subTabName) => {
    return subTabName.toLowerCase().replace(/\s+/g, '-');
  };

  const getFilteredScenarios = () => {
    if (activeTab === 'Education') {
      if (activeSubTab === '') {
        return filteredEducationScenarios;
      } else {
        const subTabTag = mapSubTabToTag(activeSubTab);
        return scenarios.filter((scenario) => {
          const lowerCaseTags = scenario.tags.map((tag) => tag.toLowerCase());
          return lowerCaseTags.includes(subTabTag);
        });
      }
    } else if (activeTab === 'Entertainment') {
      return filteredEntertainmentScenarios;
    } else if (activeTab === 'Most Viewed') {
      return popularScenarios;
    } else if (activeTab === 'Recently Published') {
      return recentlyCreatedScenarios;
    } else if (activeTab === 'Recently Updated') {
      return recentlyUpdatedScenarios;
    } else if (activeTab === 'Featured') {
      return filteredFeaturedScenarios;
    }
    return [];
  };

  return (
    <div className={`${styles.container}`}>
      {!isLoading && (
        <div>
          <TabsBar
            activeTab={activeTab}
            setActiveTab={setActiveTab}
            activeSubTab={activeSubTab}
            setActiveSubTab={setActiveSubTab}
          />
          <ScenarioCarousel scenariosList={getFilteredScenarios()} />
        </div>
      )}
    </div>
  );
};

export default ScenarioCarousels;

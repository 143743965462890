import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import styles from './styles/SetOrder.module.css';
import CanvasContext from '../../../context/CanvasContext';
import { DragDropContext, Droppable, Draggable } from '@hello-pangea/dnd';

const SetOrder = ({ closePanel }) => {
    const { situations, scenario, logOrder, setLogOrder } = useContext(CanvasContext);
    const [orderedSituations, setOrderedSituations] = useState([]);
    const [showInconsistencyPrompt, setShowInconsistencyPrompt] = useState(false);

    useEffect(() => {
        // Initialize orderedSituations based on logOrder or default order
        if (logOrder && logOrder.length > 0) {
            const ordered = logOrder.map(id => situations.find(situation => situation._id === id));

            // Check for undefined elements in the ordered array
            if (ordered.includes(undefined) || ordered.length !== situations.length) {
                setShowInconsistencyPrompt(true);
            } else {
                setOrderedSituations(ordered);
            }
        } else {
            setOrderedSituations(situations);
        }
    }, [logOrder, situations]);

    const handleOnDragEnd = (result) => {
        if (!result.destination) return;

        const items = Array.from(orderedSituations);
        const [reorderedItem] = items.splice(result.source.index, 1);
        items.splice(result.destination.index, 0, reorderedItem);

        setOrderedSituations(items);

        // Save the new order automatically after dragging
        const newLogOrder = items.map(situation => situation._id);
        axios
            .patch(`${process.env.REACT_APP_API_URL_LOCAL}/api/scenarios/${scenario?._id}`, { logOrder: newLogOrder })
            .then(() => {
                setLogOrder(newLogOrder); // Update the log order in the parent component
            })
            .catch((error) => {
                console.error('Error updating log order:', error);
            });
    };

    const handleClearOrder = (clearOrder) => {
        if (clearOrder) {
            axios
                .patch(`${process.env.REACT_APP_API_URL_LOCAL}/api/scenarios/${scenario?._id}`, { logOrder: [] })
                .then(() => {
                    setLogOrder([]); // Clear the log order in the parent component
                    setOrderedSituations(situations); // Reset to the default order
                    setShowInconsistencyPrompt(false);
                })
                .catch((error) => {
                    console.error('Error clearing log order:', error);
                });
        } else {
            setShowInconsistencyPrompt(false); // Just close the prompt without making changes
        }
    };

    return (
        <div className={styles.container}>
            {showInconsistencyPrompt ? (
                <div className={styles.promptContainer}>
                    <p>Inconsistency detected in the order. Would you like to clear the order?</p>
                    <button onClick={() => handleClearOrder(true)} className={styles.yesButton}>
                        Yes
                    </button>
                    <button onClick={() => handleClearOrder(false)} className={styles.noButton}>
                        No
                    </button>
                </div>
            ) : (
                <>
                    <DragDropContext onDragEnd={handleOnDragEnd}>
                        <Droppable droppableId="situations">
                            {(provided) => (
                                <div
                                    className={styles.situationsList}
                                    {...provided.droppableProps}
                                    ref={provided.innerRef}
                                >
                                    {orderedSituations.map((situation, index) => (
                                        <Draggable key={situation._id} draggableId={situation._id} index={index}>
                                            {(provided, snapshot) => (
                                                <div
                                                    className={`${styles.situationItem} ${
                                                        snapshot.isDragging ? styles.situationItemDragging : ''
                                                    }`}
                                                    ref={provided.innerRef}
                                                    {...provided.draggableProps}
                                                    {...provided.dragHandleProps}
                                                >
                                                    <div className={styles.situationTitle}>
                                                        {situation.title || 'Untitled'}
                                                    </div>
                                                    <div className={styles.positionNumber}>{index + 1}</div>
                                                </div>
                                            )}
                                        </Draggable>
                                    ))}
                                    {provided.placeholder}
                                </div>
                            )}
                        </Droppable>
                    </DragDropContext>
                    {/* Removed the Save button */}
                    {/* Optional Close Button */}
                    {/* <button onClick={closePanel} className={styles.closeButton}>Close</button> */}
                </>
            )}
        </div>
    );
};

export default SetOrder;

// SituationsAndGroups.js
import React, { useContext, useEffect, useState } from 'react';
import CanvasContext from '../../../context/CanvasContext';
import styles from './styles/SituationsAndGroups.module.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretRight, faCaretDown } from '@fortawesome/free-solid-svg-icons';

const SituationsAndGroups = () => {
    const {
        groups,
        situations,
        selectedGroup,
        selectedSituation,
        getGroup,
        getSituation,
        centerNodeInView,
    } = useContext(CanvasContext);

    const [collapsedGroups, setCollapsedGroups] = useState({});
    const [groupedSituations, setGroupedSituations] = useState({});

    useEffect(() => {
        const newGroupedSituations = groups.reduce((acc, group) => {
            acc[group._id] = situations.filter(situation =>
                situation.situationGroup && situation.situationGroup._id === group._id
            );
            return acc;
        }, {});

        setGroupedSituations(newGroupedSituations);
    }, [groups, situations]);

    return (
        <div className={styles.container}>
            <div className={styles.list}>
                {groups.map((group) => (
                    <div key={group._id}>
                        <div
                            className={`${styles.groupTitle} ${selectedGroup?._id === group._id ? styles.selectedGroup : ''}`}
                            style={{
                                color: group.color === '#ffffff' ? 'black' : group.color,
                            }}
                            onClick={() => getGroup(group._id)}
                        >
                            <span
                                onClick={(event) => {
                                    event.stopPropagation();
                                    setCollapsedGroups(prev => ({
                                        ...prev,
                                        [group._id]: !prev[group._id],
                                    }));
                                }}
                            >
                                {collapsedGroups[group._id]
                                    ? <FontAwesomeIcon icon={faCaretRight} />
                                    : <FontAwesomeIcon icon={faCaretDown} />}
                            </span>
                            {group.title || 'Untitled Group'}
                            {collapsedGroups[group._id] && (
                                <span>&nbsp;({groupedSituations[group._id]?.length || 0})</span>
                            )}
                        </div>

                        {!collapsedGroups[group._id] && groupedSituations[group._id]?.map(situation => (
                            <div
                                key={situation._id}
                                className={`${styles.situationItem} ${styles.groupedSituationItem} ${selectedSituation?._id === situation._id ? styles.selectedSituation : ''}`}
                                onClick={() => {
                                    getSituation(situation._id);
                                    centerNodeInView(situation._id);
                                }}
                                style={{
                                    '--bullet-color': group.color === '#ffffff' ? '#000' : group.color
                                }}
                            >
                                {situation.title || 'Untitled Situation'}
                            </div>
                        ))}
                    </div>
                ))}

                {/* Situations without a group */}
                {situations.filter(situation => !situation.situationGroup).map(situation => (
                    <div
                        key={situation._id}
                        className={`${styles.situationItem} ${selectedSituation?._id === situation._id ? styles.selectedSituation : ''}`}
                        onClick={() => {
                            getSituation(situation._id);
                            centerNodeInView(situation._id);
                        }}
                        style={{ '--bullet-color': '#000' }}
                    >
                        {situation.title || 'Untitled Situation'}
                    </div>
                ))}
            </div>
        </div>
    );
};

export default SituationsAndGroups;

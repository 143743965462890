// ScenarioTitlePage.js
import React, { useState, useEffect, useContext, useRef, useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import axios from 'axios';
import { useNavigate, useParams } from 'react-router-dom';
import AuthContext from '../../../context/AuthContext';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { CKFinderUploadAdapter, MediaEmbed } from '@ckeditor/ckeditor5-build-classic';
import ScenarioTitlePageTags from './ScenarioTitlePageTags';
import styles from './styles/ScenarioTitlePage.module.css';

const ScenarioTitlePage = () => {
    const navigate = useNavigate();
    const { scenarioId } = useParams();
    const { userId } = useContext(AuthContext);

    const [scenario, setScenario] = useState({ title: '', description: '', image: null });
    const [tags, setTags] = useState([]);
    const [isPrivate, setIsPrivate] = useState(false);
    const [showMap, setShowMap] = useState(false);
    const [password, setPassword] = useState('');
    const [isHidden, setIsHidden] = useState(false);
    const [isLogged, setIsLogged] = useState(false);
    const [initialData, setInitialData] = useState('');
    const [originalIsLogged, setOriginalIsLogged] = useState(false);
    const editorRef = useRef(null);

    const { getRootProps, getInputProps } = useDropzone({
        onDrop: useCallback((acceptedFiles) => {
            if (acceptedFiles.length) {
                setScenario((prev) => ({ ...prev, image: acceptedFiles[0] }));
            }
        }, []),
    });

    const fetchScenarioData = useCallback(async () => {
        try {
            const { data } = await axios.get(`${process.env.REACT_APP_API_URL_LOCAL}/api/scenarios/${scenarioId}`, {
                headers: { Authorization: `Bearer ${userId}` },
            });

            if (data) {
                setScenario({
                    title: data.title || '',
                    description: data.description || '',
                    image: data.image || null,
                });
                setIsPrivate(data.isPrivate || false);
                setPassword(data.password || '');
                setIsHidden(data.isHidden || false);
                setIsLogged(data.isLogged || false);
                setShowMap(data.showMap || false);
                setTags(data.tags || []);
                setInitialData(data.description);

                // Track the original logged status
                setOriginalIsLogged(data.isLogged || false);

                if (!data.isEditing) {
                    await axios.patch(`${process.env.REACT_APP_API_URL_LOCAL}/api/scenarios/${scenarioId}`, { isEditing: true });
                }
            }
        } catch (error) {
            console.error('Error fetching scenario data:', error);
            if (error.response?.status === 403) {
                navigate('/home');
            } else {
                alert('An error occurred while verifying ownership.');
            }
        }
    }, [scenarioId, userId, navigate]);

    useEffect(() => {
        fetchScenarioData();
    }, [fetchScenarioData]);

    useEffect(() => {
        if (editorRef.current) {
            editorRef.current.setData(initialData);
        }
    }, [initialData]);

    const handleInputChange = useCallback((event) => {
        const { name, value } = event.target;
        setScenario((prev) => ({ ...prev, [name]: value }));
    }, []);

    const handleCheckboxChange = useCallback(() => {
        setIsPrivate((prev) => {
            if (prev) setPassword('');
            return !prev;
        });
    }, []);

    const handleHiddenChange = useCallback(() => {
        setIsHidden((prev) => !prev);
    }, []);

    const handleLoggedChange = useCallback(() => {
        setIsLogged((prev) => !prev);
    }, []);

    const handleShowMapChange = useCallback(() => {
        setShowMap((prev) => !prev);
    }, []);

    const handleSubmit = useCallback(async () => {
        try {
            const formData = new FormData();
            formData.append('title', scenario.title);
            formData.append('description', scenario.description);
            formData.append('tags', tags.join(','));
            formData.append('isPrivate', isPrivate);
            formData.append('password', password);
            formData.append('isHidden', isHidden);
            formData.append('isLogged', isLogged);
            formData.append('showMap', showMap);
            if (scenario.image) formData.append('image', scenario.image);

            // Update the scenario
            await axios.patch(`${process.env.REACT_APP_API_URL_LOCAL}/api/scenarios/${scenarioId}`, formData);
            console.log('Scenario updated successfully');

            // Check if isLogged has changed
            if (isLogged !== originalIsLogged) {
                // Fetch situations and update their isLogged status
                const { data: situations } = await axios.get(`${process.env.REACT_APP_API_URL_LOCAL}/api/situations/${scenarioId}/all`, {
                    headers: { Authorization: `Bearer ${userId}` },
                });

                await Promise.all(
                    situations.map((situation) =>
                        axios.patch(`${process.env.REACT_APP_API_URL_LOCAL}/api/situations/situation/${situation._id}`, { isLogged })
                    )
                );
            }

            navigate(`/create/${scenarioId}/canvas`);
        } catch (error) {
            console.error('Error updating scenario:', error);
        }
    }, [scenario, tags, isPrivate, password, isHidden, isLogged, showMap, scenarioId, userId, originalIsLogged, navigate]);

    const deleteImageFromServer = useCallback(async () => {
        try {
            await axios.patch(`${process.env.REACT_APP_API_URL_LOCAL}/api/scenarios/${scenarioId}/deleteImage`);
            setScenario((prev) => ({ ...prev, image: null }));
            console.log('Image deleted successfully');
        } catch (error) {
            console.error('Error deleting image:', error);
        }
    }, [scenarioId]);

    return (
        <div className={styles.container}>
            <h1 className={styles.heading}>Edit Your Title Page</h1>
            <div className={styles.form}>
                <div className={styles.imageUploadContainer}>
                    <div {...getRootProps()} className={styles.dropzone}>
                        <input {...getInputProps()} />
                        {scenario.image ? (
                            <>
                                <div className={styles.imagePreview}>
                                    <img
                                        src={
                                            typeof scenario.image === 'string'
                                                ? scenario.image
                                                : URL.createObjectURL(scenario.image)
                                        }
                                        alt="Scenario"
                                    />
                                </div>
                                <button
                                    type="button"
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        deleteImageFromServer();
                                    }}
                                    className={styles.removeImageButton}
                                >
                                    Remove Image
                                </button>
                            </>
                        ) : (
                            <span className={styles.addImageText}>+</span>
                        )}
                    </div>
                </div>
    
                <label className={styles.label}>Title</label>
                <input
                    type="text"
                    name="title"
                    autoFocus
                    value={scenario.title}
                    onChange={handleInputChange}
                    className={styles.titleInput}
                    maxLength={60}
                />
                <div className={styles.charCount}>{60 - scenario.title.length} characters remaining</div>
    
                <label className={styles.label}>Description</label>
                <CKEditor
                    editor={ClassicEditor}
                    data={initialData}
                    plugins={[CKFinderUploadAdapter, MediaEmbed]}
                    config={{
                        ckfinder: {
                            uploadUrl: `${process.env.REACT_APP_API_URL_LOCAL}/api/images/upload`,
                        },
                        mediaEmbed: { elementName: 'iframe' },
                    }}
                    onReady={(editor) => (editorRef.current = editor)}
                    onChange={(event, editor) =>
                        setScenario((prev) => ({ ...prev, description: editor.getData() }))
                    }
                />
    
                <div className={styles.checkboxGroup}>
                    <label className={styles.checkboxLabel}>
                        <input
                            type="checkbox"
                            checked={isPrivate}
                            onChange={handleCheckboxChange}
                            className={styles.checkbox}
                        />
                        Add a Password
                    </label>
                    {isPrivate && (
                        <>
                            <label className={styles.label}>Enter a password</label>
                            <input
                                type="text"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                className={styles.input}
                            />
                        </>
                    )}
                    <label className={styles.checkboxLabel}>
                        <input
                            type="checkbox"
                            checked={isHidden}
                            onChange={handleHiddenChange}
                            className={styles.checkbox}
                        />
                        Hide From Public
                    </label>
                    <label className={styles.checkboxLabel}>
                        <input
                            type="checkbox"
                            checked={isLogged}
                            onChange={handleLoggedChange}
                            className={styles.checkbox}
                        />
                        Create Logs
                    </label>
                    <label className={styles.checkboxLabel}>
                        <input
                            type="checkbox"
                            checked={showMap}
                            onChange={handleShowMapChange}
                            className={styles.checkbox}
                        />
                        Display Map
                    </label>
                </div>
    
                <ScenarioTitlePageTags tags={tags} setTags={setTags} />
    
                <div className={styles.buttonFooter}>
                    <button className={styles.button} onClick={() => navigate('/home')}>
                        Home
                    </button>
                    <button className={styles.button} onClick={handleSubmit}>
                        Next
                    </button>
                </div>
            </div>
        </div>
    );
    
};

export default React.memo(ScenarioTitlePage);

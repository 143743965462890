import React, { useContext, useState, useEffect } from 'react';
import CanvasContext from '../../../context/CanvasContext';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { CKFinderUploadAdapter, MediaEmbed } from '@ckeditor/ckeditor5-build-classic';
import EditSidebarTabs from './EditSidebarTabs';
import StyledSwitch from './StyledSwitch';
import textStyles from './styles/EditSidebarText.module.css';
import settingsStyles from './styles/EditSidebarSettings.module.css';
import LinkSituation from './LinkSituation';
import DeleteModal from './DeleteModal';

const EditChoice = () => {
    const { updateChoice, deleteChoice, selectedChoice, updateEdges, selectedEdgeId, edges, activeEditTab, loading } = useContext(CanvasContext);
    const [title, setTitle] = useState(selectedChoice.title || '');
    const [text, setText] = useState(selectedChoice.text || '');
    const [hasHint, setHasHint] = useState(selectedChoice.hasHint || false);
    const [hintText, setHintText] = useState(selectedChoice.hint || '');
    const [nextSituation, setNextSituation] = useState(selectedChoice.nextSituation || null);

    // Modal
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

    useEffect(() => {
        if (selectedChoice) {
            setTitle(selectedChoice.title || '');
            setText(selectedChoice.text || '');
            setHasHint(selectedChoice.hasHint || false);
            setHintText(selectedChoice.hint || '');
            setNextSituation(selectedChoice.nextSituation || null);
        }
    }, [selectedChoice]);

    const handleTitleChange = (event) => {
        setTitle(event.target.value);
    };

    const handleTitleBlur = async () => {
        if (title !== selectedChoice.title) {
            try {
                await updateChoice({ title: title });
                const updatedEdges = edges.map(edge => 
                    edge.id === selectedEdgeId ? { ...edge, data: { ...edge.data, label: title } } : edge
                );
                await updateEdges(updatedEdges);
            } catch (error) {
                console.error("Error updating choice or canvas: ", error);
            }
        }
    };

    // Separate handler for the main text editor
    const handleTextEditorChange = (data) => {
        setText(data);
    };

    const handleTextEditorBlur = async () => {
        if (text !== selectedChoice.text) {
            try {
                console.log("text editor blur");
                await updateChoice({ text: text });
            } catch (error) {
                console.error("Error updating choice text: ", error);
            }
        }
    };

    // Separate handler for the hint text editor
    const handleHintEditorChange = (data) => {
        setHintText(data);
    };

    const handleHintEditorBlur = async () => {
        if (hintText !== selectedChoice.hint) {
            try {
                await updateChoice({ hint: hintText });
            } catch (error) {
                console.error("Error updating hint text: ", error);
            }
        }
    };

    const handleDeleteChoice = () => {
        setIsDeleteModalOpen(true);
    };

    const confirmDeleteChoice = async () => {
        try {
            await deleteChoice(selectedEdgeId);

            const updatedEdges = edges.filter(edge => edge.id !== selectedEdgeId);
            await updateEdges(updatedEdges);

            setIsDeleteModalOpen(false);
            console.log(`Choice ${selectedEdgeId} deleted and edges updated.`);
        } catch (error) {
            console.error('Error deleting choice:', error);
        }
    };

    const cancelDeleteChoice = () => {
        setIsDeleteModalOpen(false);
    };

    return (
        <>
            <EditSidebarTabs editType='choice' />
            {activeEditTab === '1' && (
                <div className={textStyles.textContainer}>
                    <input
                        type="text"
                        value={title}
                        onChange={handleTitleChange}
                        onBlur={handleTitleBlur}
                        className={textStyles.titleInput}
                    />
                    <CKEditor
                        editor={ClassicEditor}
                        data={text}
                        plugins={[CKFinderUploadAdapter, MediaEmbed]}
                        config={{
                            toolbar: {
                                items: [
                                    'undo', 'redo',
                                    '|',
                                    'heading',
                                    '|',
                                    'bold', 'italic',
                                    '|',
                                    'link', 'uploadImage', 'blockQuote',
                                    '|',
                                    'bulletedList', 'numberedList', 'outdent', 'indent'
                                ],
                                shouldNotGroupWhenFull: true
                            },
                            ckfinder: {
                                uploadUrl: `${process.env.REACT_APP_API_URL_LOCAL}/api/images/upload`
                            },
                            mediaEmbed: {
                                elementName: 'iframe',
                            }
                        }}
                        onChange={(event, editor) => handleTextEditorChange(editor.getData())}
                        onBlur={handleTextEditorBlur}
                    />
                </div>
            )}

            {activeEditTab === '2' && (
                <>
                    <LinkSituation nextSituation={nextSituation} />
                </>
            )}

            {activeEditTab === '3' && (
                <div className={settingsStyles.settingsContainer}>
                    <div className={settingsStyles.settingItem}>
                        <label>
                            Enable Hint
                        </label>
                        <StyledSwitch 
                            onChange={(value) => updateChoice({ hasHint: value })}
                            checked={hasHint}
                            disabled={
                                !selectedChoice?.nextSituation || 
                                selectedChoice?.nextSituation?._id !== selectedChoice?.situation
                            } // Safeguard: Disable if nextSituation doesn't exist
                        />
                    </div>
                    
                    {hasHint && (
                        <div className={settingsStyles.expandedSettingItem}>
                            <CKEditor
                                editor={ClassicEditor}
                                data={hintText}
                                plugins={[CKFinderUploadAdapter, MediaEmbed]}
                                config={{
                                    toolbar: {
                                        items: [
                                            'undo', 'redo',
                                            '|',
                                            'heading',
                                            '|',
                                            'bold', 'italic',
                                            '|',
                                            'link', 'uploadImage', 'blockQuote',
                                            '|',
                                            'bulletedList', 'numberedList', 'outdent', 'indent'
                                        ],
                                        shouldNotGroupWhenFull: true
                                    },
                                    ckfinder: {
                                        uploadUrl: `${process.env.REACT_APP_API_URL_LOCAL}/api/images/upload`
                                    },
                                    mediaEmbed: {
                                        elementName: 'iframe',
                                    }
                                }}
                                onChange={(event, editor) => handleHintEditorChange(editor.getData())}
                                onBlur={handleHintEditorBlur}
                            />
                        </div>
                    )}
                    {/* Delete Choice Button */}
                    <button
                        onClick={handleDeleteChoice}
                        className={textStyles.deleteButton}
                    >
                        Delete Choice
                    </button>
                </div>
            )}

            {/* Modals */}
            {isDeleteModalOpen && (
                <DeleteModal
                    onConfirm={confirmDeleteChoice}
                    onCancel={cancelDeleteChoice}
                    type={'Choice'}
                />
            )}
        </>
    );
};

export default EditChoice;

// CreateScenario.js
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { ReactFlowProvider } from 'react-flow-renderer';
import CreateSidebar from './CreateSidebar';
import Canvas from './Canvas';
import EditSidebar from './EditSidebar';
import { CanvasProvider } from '../../../context/CanvasContext';
import styles from './styles/CreateScenario.module.css';
import axios from 'axios';
import TopBar from './TopBar';

const CreateScenario = () => {
    const { scenarioId } = useParams();
    const [isAuthorized, setIsAuthorized] = useState(false);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    // Resizable edit sidebar width
    const [editSidebarWidth, setEditSidebarWidth] = useState(20); // In percentage
    const [renderEditSidebar, setRenderEditSidebar] = useState(true);

    // Handle dragging to resize the edit sidebar
    const handleEditSidebarResize = (e) => {
        e.preventDefault();
        document.onmousemove = (event) => {
            const newWidth = ((window.innerWidth - event.clientX) / window.innerWidth) * 100;
            if (newWidth > 20 && newWidth < 40) { // Limit resize range between 20% and 40%
                setEditSidebarWidth(newWidth);
            }
        };
        document.onmouseup = () => {
            document.onmousemove = null;
        };
    };

    useEffect(() => {
        const verifyOwnership = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_URL_LOCAL}/api/scenarios/${scenarioId}/verifyOwnership`);
                setIsAuthorized(true);
                setLoading(false);
            } catch (err) {
                console.error("Ownership verification failed:", err);
                setIsAuthorized(false);
                setLoading(false);
                if (err.response?.status === 403) {
                    setError("Access Denied");
                } else if (err.response?.status === 404) {
                    setError("Scenario not found.");
                } else {
                    setError("Access Denied");
                }
            }
        };

        verifyOwnership();
    }, [scenarioId]);

    if (loading) {
        return <div>Loading...</div>;
    }

    if (error) {
        return <div>{error}</div>;
    }

    if (!isAuthorized) {
        return null;
    }

    return (
        <ReactFlowProvider>
            <CanvasProvider scenarioId={scenarioId}>
                <TopBar
                    renderEditSidebar={renderEditSidebar}
                    setRenderEditSidebar={setRenderEditSidebar}
                />
                <div className={styles.container}>
                    {/* CreateSidebar */}
                    <div className={styles.createSidebar}>
                        <CreateSidebar />
                    </div>

                    {/* Canvas section */}
                    <div
                        className={styles.canvas}
                        style={{
                            width: `${100 - (renderEditSidebar ? editSidebarWidth : 0)}%`
                        }}
                    >
                        <Canvas setRenderEditSidebar={setRenderEditSidebar} />
                    </div>

                    {renderEditSidebar && (
                        <>
                            {/* Divider for resizing the EditSidebar */}
                            <div
                                className={styles.resizer}
                                onMouseDown={handleEditSidebarResize}
                            />

                            {/* EditSidebar with resizable width */}
                            <div
                                className={styles.editSidebar}
                                style={{ width: renderEditSidebar ? `${editSidebarWidth}%` : '0%' }}
                            >
                                <EditSidebar />
                            </div>
                        </>
                    )}
                </div>
            </CanvasProvider>
        </ReactFlowProvider>
    );
};

export default CreateScenario;

import React, { useEffect, useRef, useState } from "react";
import styles from './styles/TabsBar.module.css';
import EducationTabDropdown from './EducationTabDropdown';
import { FaCaretDown } from "react-icons/fa";

const TabsBar = ({ activeTab, setActiveTab, activeSubTab, setActiveSubTab }) => {
    const [alignLeft, setAlignLeft] = useState(false);
    const tabsBarRef = useRef(null);
    const tabsContentRef = useRef(null);
    const educationTabRef = useRef(null);

    const [showEducationDropdown, setShowEducationDropdown] = useState(false);

    const tabs = ["Featured", "Most Viewed", "Education", "Entertainment", "Recently Published", "Recently Updated", "Recommended"];

    useEffect(() => {
        const checkTabsWidth = () => {
            if (tabsContentRef.current && tabsBarRef.current) {
                const contentWidth = tabsContentRef.current.scrollWidth;
                const containerWidth = tabsBarRef.current.clientWidth;
                setAlignLeft(contentWidth > containerWidth);
            }
        };

        checkTabsWidth();
        window.addEventListener('resize', checkTabsWidth);
        return () => window.removeEventListener('resize', checkTabsWidth);
    }, []);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (
                showEducationDropdown &&
                educationTabRef.current &&
                !educationTabRef.current.contains(event.target) &&
                !document.getElementById('educationDropdown')?.contains(event.target)
            ) {
                setShowEducationDropdown(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [showEducationDropdown]);

    const handleTabClick = (tab) => {
        if (tab === "Education") {
            setShowEducationDropdown(!showEducationDropdown);
        } else {
            setActiveTab(tab);
            setActiveSubTab(""); // Reset subtab when other tab is clicked
            setShowEducationDropdown(false);
        }
    };

    const handleEducationItemClick = (subTab) => {
        setActiveTab("Education");
        setActiveSubTab(subTab);
        setShowEducationDropdown(false);
    };

    const handleViewAllClick = () => {
        setActiveTab("Education");
        setActiveSubTab("");
        setShowEducationDropdown(false);
    };


    return (
        <div style={{ position: 'relative' }}>
            <div
                className={styles.tabsBar}
                ref={tabsBarRef}
                data-align-left={alignLeft}
            >
                <div className={styles.tabsContent} ref={tabsContentRef}>
                    {tabs.map(tab => (
                        tab === "Education" ? (
                            <div
                                key={tab}
                                className={`${styles.tab} ${activeTab === tab ? styles.activeTab : ''}`}
                                onClick={() => handleTabClick(tab)}
                                ref={educationTabRef}
                            >
                                {tab}
                                <FaCaretDown className={styles.caretIcon} />
                            </div>
                        ) : (
                            <div
                                key={tab}
                                className={`${styles.tab} ${activeTab === tab ? styles.activeTab : ''}`}
                                onClick={() => handleTabClick(tab)}
                            >
                                {tab}
                            </div>
                        )
                    ))}
                </div>
            </div>
            {showEducationDropdown && (
                <div
                    id="educationDropdown"
                    className={styles.dropdownWrapper}
                >
                    <EducationTabDropdown
                        handleItemClick={handleEducationItemClick}
                        handleViewAllClick={handleViewAllClick}
                        handleClose={() => setShowEducationDropdown(false)}
                    />
                </div>
            )}
        </div>
    );
};

export default TabsBar;

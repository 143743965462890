import React, { useState, useEffect } from 'react';
import styles from './styles/MyClassroomsSidebar.module.css';
import { FaHome } from 'react-icons/fa';
import { Link, useParams } from 'react-router-dom';

const ClassroomSidebar = ({ classrooms, joinedClassrooms, myClassroomsTabOpen, setMyClassroomsTabOpen }) => {
    const [selectedClassroomId, setSelectedClassroomId] = useState(null);
    const classroomIdObj = useParams();
    const rawClassroomId = classroomIdObj["*"];
    const actualClassroomId = rawClassroomId.split('/')[0]; 

    useEffect(() => {
        if (actualClassroomId) {
            setSelectedClassroomId(actualClassroomId);
        }
    }, [actualClassroomId]);

    return (
        <div className={styles.sidebarContainer}>
            <div className={styles.sidebarOptions}>
                <Link to="/my-classrooms" className={styles.linkStyle}>
                    <div
                        className={`${styles.sidebarClassroomItem} ${myClassroomsTabOpen ? styles.sidebarClassroomItemSelected : ''}`}
                        onClick={() => {
                            setSelectedClassroomId(null);
                            setMyClassroomsTabOpen(true);
                        }}
                    >
                        <FaHome className={styles.icon} />Home
                    </div>
                </Link>
            </div>

            {/* My Classrooms Section */}
            <div className={styles.sectionContainer}>
                <div className={styles.sectionTitle}>My Classrooms</div>
                <div className={styles.sidebarClassrooms}>
                    {classrooms.slice().reverse().map((classroom) => (
                        <Link to={`/my-classrooms/${classroom._id}/a`} key={classroom._id} className={styles.linkStyle}>
                            <div
                                className={`${styles.sidebarClassroomItem} ${selectedClassroomId === classroom._id ? styles.sidebarClassroomItemSelected : ''}`}
                                onClick={() => {
                                    setSelectedClassroomId(classroom._id);
                                    setMyClassroomsTabOpen(false);
                                }}
                            >
                                {classroom.name}
                            </div>
                        </Link>
                    ))}
                </div>
            </div>

            {/* Divider */}
            <div className={styles.divider}></div>

            {/* Joined Classrooms Section */}
            <div className={styles.sectionContainer}>
                <div className={styles.sectionTitle}>Joined Classrooms</div>
                <div className={styles.sidebarClassrooms}>
                    {joinedClassrooms.slice().reverse().map((classroom) => (
                        <Link to={`/my-classrooms/${classroom._id}/a`} key={classroom._id} className={styles.linkStyle}>
                            <div
                                className={`${styles.sidebarClassroomItem} ${selectedClassroomId === classroom._id ? styles.sidebarClassroomItemSelected : ''}`}
                                onClick={() => {
                                    setSelectedClassroomId(classroom._id);
                                    setMyClassroomsTabOpen(false);
                                }}
                            >
                                {classroom.name}
                            </div>
                        </Link>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default ClassroomSidebar;

import React, { useEffect, useState, useContext } from 'react';
import ViewScenarioTitlePage from './ViewScenarioTitlePage';
import ViewSituation from './ViewSituation';
import { useParams, useMatch, useNavigate, useLocation } from 'react-router-dom';
import { useScenario } from '../../../context/ScenarioContext';
import axios from 'axios';
import ViewTableOfContents from './ViewTableOfContents';
import styles from './styles/ViewScenario.module.css';
import AuthContext from '../../../context/AuthContext';
import ScenarioPassword from './ScenarioPassword';
import { FaCross, FaListOl, FaRedo, FaTimes } from 'react-icons/fa';
import { Tooltip } from 'react-tooltip'; // Import Tooltip from react-tooltip

const ViewScenario = () => {
    const location = useLocation();
    const { userId } = useContext(AuthContext); // Only get userId from AuthContext
    const { scenarioId } = useParams();
    const [scenario, setScenario] = useState(null);
    const [canvas, setCanvas] = useState(null); // Add state for canvas
    const { currentSituations, setCurrentSituationId, setLogId, completeLog } = useScenario();
    const [hasAccess, setHasAccess] = useState(location.state?.hasAccess ?? false);
    const [fromFeedback, setFromFeedback] = useState(location.state?.fromFeedback ?? false);
    const [passwordError, setPasswordError] = useState(false);
    const [isToCVisible, setIsToCVisible] = useState(false); // State to manage ToC visibility
    const preview = useMatch("/create/:scenarioId/preview");
    const navigate = useNavigate();

    const [user, setUser] = useState(null); // Add state for user
    const [userLoading, setUserLoading] = useState(true); // Add loading state

    const currentSituationId = currentSituations[scenarioId];

    useEffect(() => {
        if (location.state?.currentSituationId) {
            setCurrentSituationId(scenarioId, location.state.currentSituationId);
        }
    }, [location.state?.currentSituationId]);
    

    useEffect(() => {
        const fetchUserData = async () => {
            if (!userId) {
                setUserLoading(false);
                return;
            }
            
            try {
                const userResponse = await axios.get(`${process.env.REACT_APP_API_URL_LOCAL}/api/users/${userId}`);
                setUser(userResponse.data);

                if (userResponse.data && userResponse.data.incompleteLogs) {
                    for (const [logId, log] of Object.entries(userResponse.data.incompleteLogs)) {
                        if (log.scenarioId === scenarioId) {
                            setLogId(logId);
                            break;
                        }
                    }
                }
            } catch (error) {
                console.error('Error fetching user data:', error);
            } finally {
                setUserLoading(false);
            }
        };

        fetchUserData();
    }, [userId, scenarioId, setLogId]);

    useEffect(() => {
        const fetchScenarioData = async () => {
            try {
                const scenarioResponse = await axios.get(`${process.env.REACT_APP_API_URL_LOCAL}/api/scenarios/${scenarioId}`);
                setScenario(scenarioResponse.data);
                setPasswordError(false);

                if (!scenarioResponse.data.isPrivate || preview) {
                    setHasAccess(true);
                }

                const canvasResponse = await axios.get(`${process.env.REACT_APP_API_URL_LOCAL}/api/canvas/${scenarioId}`);
                setCanvas(canvasResponse.data);
            } catch (error) {
                console.error(`Error: ${error}`);
            }
        };

        fetchScenarioData();
    }, [scenarioId, preview]);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [currentSituationId]);

    const handleRestart = async () => {
        setCurrentSituationId(scenario._id, null);
    }

    const handleExitPreview = () => {
        navigate(`/create/${scenarioId}/canvas`);
        setCurrentSituationId(scenario._id, null);
    }

    const handleReturnToFeedback = () => {
        if (user) {
            navigate(`/user/${user.username}`, { state: { activeTab: 'Feedback' } });
        }
        setFromFeedback(false);
    }

    const toggleToC = () => {
        setIsToCVisible(!isToCVisible);
    }

    if (!scenario) {
        return <div>Loading...</div>;
    } else if (!hasAccess) {
        return (
            <ScenarioPassword
                scenarioPassword={scenario.password}
                setHasAccess={setHasAccess}
                passwordError={passwordError}
                setPasswordError={setPasswordError}
            />
        );
    } else {
        return (
            <>
                {fromFeedback && !preview && (
                    <button className={styles.previewButton} onClick={handleReturnToFeedback}>Back</button>
                )}
                {preview && (
                    <FaTimes
                        className={styles.exitPreview}
                        onClick={handleExitPreview}
                        data-tooltip-id="tooltip"
                        data-tooltip-content="Exit Preview"
                    />
                )}
                {!currentSituationId ? (
                    <>
                        <ViewScenarioTitlePage 
                            userId={userId}
                            incompleteLogs={user?.incompleteLogs}
                            scenario={scenario}
                            currentSituationId={currentSituationId}
                            setCurrentSituationId={(situationId) => setCurrentSituationId(scenario._id, situationId)}
                            preview={preview}
                        />
                    </>
                ) : (
                    <div className={styles.container}>
                        {scenario.tableOfContents && (
                            <>
                            <div className={styles.header}>
                                <FaListOl className={styles.tocButton} onClick={toggleToC} />
                                <div className={styles.tocTitle}>Table of Contents</div>
                            </div>
                                {isToCVisible && (
                                    <ViewTableOfContents
                                        tableOfContents={scenario.tableOfContents}
                                        currentSituationId={currentSituationId}
                                        setCurrentSituationId={(situationId) => setCurrentSituationId(scenario._id, situationId)}
                                        toggleToC={toggleToC}
                                    />
                                )}
                            </>
                        )}
                        <>
                        <ViewSituation
                            userId={userId}
                            scenario={scenario} 
                            canvas={canvas}
                            currentSituationId={currentSituationId}
                            setCurrentSituationId={(situationId) => setCurrentSituationId(scenario._id, situationId)}
                            preview={preview}
                            userLoading={userLoading}
                        />

                        </>
            
                        {/* Restart button with tooltip */}
                        <FaRedo
                            className={styles.restartButton}
                            onClick={handleRestart}
                            data-tooltip-id="tooltip"
                            data-tooltip-content="Restart Scenario"
                        />
                        {preview && (
                            <FaTimes
                                className={styles.exitPreview}
                                onClick={handleExitPreview}
                                data-tooltip-id="tooltip"
                                data-tooltip-content="Exit Preview"
                            />
                        )}
                    </div>
                )}
                <Tooltip id="tooltip" place="bottom" type="dark" effect="solid" style={{ zIndex: 9999 }} />
            </>
        );
    }
};

export default ViewScenario;

// CreateSituation.js
import React, { useContext } from 'react';
import styles from './styles/CreateSituation.module.css';
import CanvasContext from '../../../context/CanvasContext';
import SituationNode from './SituationNode'; // Adjust the path as necessary


const CreateSituation = () => {
    const { onDragStart, onDragEnd } = useContext(CanvasContext);

    const handleDragStart = (event, nodeType) => {
        // Clone the current target for the drag image
        const dragElement = event.currentTarget.cloneNode(true);
    
        // Position it off-screen
        dragElement.style.position = 'absolute';
        dragElement.style.top = '-1000px';
        dragElement.style.left = '-1000px';
    
        // Append to body
        document.body.appendChild(dragElement);
    
        // Use the cloned element as the drag image
        event.dataTransfer.setDragImage(dragElement, dragElement.offsetWidth / 2, dragElement.offsetHeight / 2);
    
        // Clean up after setting the drag image
        setTimeout(() => {
            document.body.removeChild(dragElement);
        }, 0);
    
        // Call the context's onDragStart function with the node type
        onDragStart(event, nodeType);
    };
    

    return (
        <div className={styles.container}>
            <div className={styles.nodeTypes}>
                {/* Draggable Situation Node */}
                <div
                    draggable
                    onDragStart={(event) => handleDragStart(event, 'ovalNode')}
                    onDragEnd={onDragEnd}
                >
                    <SituationNode data={{ label: 'Situation' }} showHandles={false} />
                </div>
    
                {/* Draggable Start Node */}
                <div
                    draggable
                    onDragStart={(event) => handleDragStart(event, 'startNode')}
                    onDragEnd={onDragEnd}
                >
                    <SituationNode data={{ label: 'START', endpointType: 'start' }} showHandles={false} />
                </div>

                <div
                    draggable
                    onDragStart={(event) => handleDragStart(event, 'endNode')}
                    onDragEnd={onDragEnd}
                >
                    <SituationNode data={{ label: 'END', endpointType: 'end' }} showHandles={false} />
                </div>
            </div>
        </div>
    );
    
};

export default CreateSituation;

import React, { useEffect, useState, useMemo } from 'react';
import styles from './styles/AccountScenarios.module.css';
import axios from 'axios';
import ScenarioCard from '../mainlayout/body/ScenarioCard';
import DeleteScenarioModal from './DeleteScenarioModal';
import CopyScenarioModal from './CopyScenarioModal';
import CategoryModal from './CategoryModal';
import { useNavigate } from 'react-router-dom';
import Masonry from 'react-masonry-css';

const AccountScenarios = ({ userIsAccountOwner, accountOwner, setAccountOwner }) => {
    const [scenarios, setScenarios] = useState([]);
    const [filteredScenarios, setFilteredScenarios] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');
    const [sortOption, setSortOption] = useState('newest'); // Default sort option
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [showCopyModal, setShowCopyModal] = useState(false);
    const [showCategoryModal, setShowCategoryModal] = useState(false);
    const [selectedScenarioId, setSelectedScenarioId] = useState(null);
    const [selectedCategory, setSelectedCategory] = useState(null);
    const [loading, setLoading] = useState(false); // Loader state
    const [editingCategory, setEditingCategory] = useState(null); // For editing category
    const navigate = useNavigate();

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_API_URL_LOCAL}/api/scenarios/user/${accountOwner._id}`)
            .then(response => {
                const sortedScenarios = response.data.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
                setScenarios(sortedScenarios);
            })
            .catch(error => console.error(`Error: ${error}`));
    }, [accountOwner]);

    const sortedAndFilteredScenarios = useMemo(() => {
        let scenariosToFilter = [...scenarios];

        if (selectedCategory) {
            scenariosToFilter = scenariosToFilter.filter(scenario =>
                selectedCategory.scenarios.includes(scenario._id)
            );
        } else {
            const query = searchQuery.trim().toLowerCase();
            if (query) {
                if (query.startsWith('#')) {
                    const tag = query.slice(1);
                    scenariosToFilter = scenariosToFilter.filter(scenario =>
                        scenario.tags.some(tagItem => tagItem.toLowerCase().includes(tag))
                    );
                } else {
                    scenariosToFilter = scenariosToFilter.filter(scenario =>
                        scenario.title.toLowerCase().includes(query) ||
                        scenario.description.toLowerCase().includes(query) ||
                        scenario.tags.some(tagItem => tagItem.toLowerCase().includes(query))
                    );
                }
            }
        }

        // Apply sorting based on selected sort option
        switch (sortOption) {
            case 'alphabetical':
                scenariosToFilter.sort((a, b) => a.title.localeCompare(b.title));
                break;
            case 'newest':
                scenariosToFilter.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
                break;
            case 'oldest':
                scenariosToFilter.sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt));
                break;
            case 'mostViewed':
                scenariosToFilter.sort((a, b) => b.views - a.views);
                break;
            case 'mostLiked':
                scenariosToFilter.sort((a, b) => b.likedBy.length - a.likedBy.length);
                break;
            default:
                break;
        }

        return scenariosToFilter;
    }, [searchQuery, scenarios, selectedCategory, sortOption]);

    useEffect(() => {
        setFilteredScenarios(sortedAndFilteredScenarios);
    }, [sortedAndFilteredScenarios]);

    const handleSearchChange = (event) => {
        setSearchQuery(event.target.value);
    };

    const handleSortChange = (event) => {
        setSortOption(event.target.value);
    };

    const handleDeleteScenario = (event, scenarioId) => {
        event.stopPropagation();
        setSelectedScenarioId(scenarioId);
        setShowDeleteModal(true);
    };

    const handleConfirmDelete = () => {
        setLoading(true); // Start loading
        axios.delete(`${process.env.REACT_APP_API_URL_LOCAL}/api/scenarios/${selectedScenarioId}`)
            .then(() => {
                setScenarios(prevScenarios => prevScenarios.filter(scenario => scenario._id !== selectedScenarioId));
            })
            .catch(error => console.error(`Error: ${error}`))
            .finally(() => {
                setLoading(false); // Stop loading
                setSelectedScenarioId(null);
                setShowDeleteModal(false);
            });
    };

    const handleCopyScenario = (event, scenarioId) => {
        event.stopPropagation();
        setSelectedScenarioId(scenarioId);
        setShowCopyModal(true);
    };

    const handleConfirmCopyStructure = () => {
        setLoading(true); // Start loading
        axios.post(`${process.env.REACT_APP_API_URL_LOCAL}/api/scenarios/${selectedScenarioId}/copy-structure`)
            .then(response => {
                const newScenarioId = response.data._id;
                setSelectedScenarioId(null);
                setShowCopyModal(false);
                navigate(`/create/${newScenarioId}`);
            }).catch(error => {
                console.error('Error copying scenario:', error);
            }).finally(() => setLoading(false)); // Stop loading
    };

    const handleConfirmCopy = () => {
        setLoading(true); // Start loading
        axios.post(`${process.env.REACT_APP_API_URL_LOCAL}/api/scenarios/${selectedScenarioId}/copy`)
            .then(response => {
                const newScenarioId = response.data._id;
                setSelectedScenarioId(null);
                setShowCopyModal(false);
                navigate(`/create/${newScenarioId}`);
            }).catch(error => {
                console.error('Error copying scenario:', error);
            }).finally(() => setLoading(false)); // Stop loading
    };

    const handleCancelDelete = () => {
        setSelectedScenarioId(null);
        setShowDeleteModal(false);
    };

    const handleCancelCopy = () => {
        setSelectedScenarioId(null);
        setShowCopyModal(false);
    };

    const handleCategoryClick = (category) => {
        setSelectedCategory(prevCategory => prevCategory === category ? null : category);
    };

    const handleAddCategoryClick = () => {
        setEditingCategory(null); // Reset editing category
        setShowCategoryModal(true);
    };

    const handleEditCategoryClick = (category) => {
        setEditingCategory(category);
        setShowCategoryModal(true);
    };

    const handleCloseCategoryModal = () => {
        setShowCategoryModal(false);
    };

    const breakpointColumnsObj = {
        default: 5,
        1700: 4,
        1450: 3,
        1200: 2,
        950: 1
    };

    return (
        <>
            <div className={styles.container}>
                <div className={styles.searchBar}>
                    <input
                        placeholder="Search my scenarios..."
                        value={searchQuery}
                        onChange={handleSearchChange}
                        className={styles.searchInput}
                    />
                </div>
                <div className={styles.sortContainer}>
                    <span className={styles.sortLabel}>Sort by:</span>
                    <select
                        value={sortOption}
                        onChange={handleSortChange}
                        className={styles.sortDropdown}
                    >
                        <option value="newest">Newest</option>
                        <option value="oldest">Oldest</option>
                        <option value="alphabetical">Alphabetical</option>
                        <option value="mostViewed">Most Viewed</option>
                        <option value="mostLiked">Most Liked</option>
                    </select>
                </div>
                <div className={styles.categories}>
                    {accountOwner.categories.map(category => (
                        <div
                            key={category.label}
                            className={`
                                ${styles.categoryTag} 
                                ${selectedCategory === category ? styles.selectedCategory : ''}
                            `}
                            style={{ backgroundColor: category.color }}
                            onClick={() => handleCategoryClick(category)}
                            onDoubleClick={() => {
                                if (userIsAccountOwner) {
                                    handleEditCategoryClick(category);
                                }
                            }}
                        >
                            {category.label}
                        </div>
                    ))}
                    {userIsAccountOwner && (
                        <button className={styles.addCategoryButton} onClick={handleAddCategoryClick}>
                            Add Category
                        </button>
                    )}
                </div>
                <Masonry
                    breakpointCols={breakpointColumnsObj}
                    className={styles.myMasonryGrid}
                    columnClassName={styles.myMasonryGridColumn}
                >
                    {filteredScenarios
                        .filter(scenario => {
                            if (userIsAccountOwner) {
                                // The owner can see all scenarios, regardless of published or hidden status
                                return true;
                            } else {
                                // For non-owners, only show scenarios that are published and not hidden
                                return scenario.isPublished && (scenario.isHidden === false || scenario.isHidden == null);
                            }
                        })
                        .map(scenario => (
                            <div key={scenario._id}>
                                <ScenarioCard
                                    scenario={scenario}
                                    isAuthor={userIsAccountOwner}
                                    onDeleteScenario={handleDeleteScenario}
                                    onCopyScenario={handleCopyScenario}
                                    searchQuery={searchQuery}
                                />
                            </div>
                        ))
                    }
                </Masonry>
            </div>

            {showDeleteModal && (
                <DeleteScenarioModal
                    handleConfirmDelete={handleConfirmDelete}
                    handleCancelDelete={handleCancelDelete}
                />
            )}
            {showCopyModal && (
                <CopyScenarioModal
                    handleConfirmCopy={handleConfirmCopy}
                    handleConfirmCopyStructure={handleConfirmCopyStructure}
                    handleCancelCopy={handleCancelCopy}
                />
            )}
            {showCategoryModal && (
                <CategoryModal
                    onClose={handleCloseCategoryModal}
                    scenarios={scenarios}
                    accountOwner={accountOwner}
                    setAccountOwner={setAccountOwner}
                    category={editingCategory}
                />
            )}

            {loading && (
                <>
                    <div className={`${styles.overlay} ${loading ? styles.active : ''}`}></div>
                    <div className={styles.loader}>
                        <div className={styles.spinner}></div>
                    </div>
                </>
            )}
        </>
    );
};

export default AccountScenarios;

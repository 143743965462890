import React, { useContext, useState, useEffect } from 'react';
import CanvasContext from '../../../context/CanvasContext';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { CKFinderUploadAdapter, MediaEmbed } from '@ckeditor/ckeditor5-build-classic';
import EditSidebarTabs from './EditSidebarTabs';
import SituationsList from './SituationsList';
import UploadModal from './UploadModal';
import textStyles from './styles/EditSidebarText.module.css';
import settingsStyles from './styles/EditSidebarSettings.module.css';
import DeleteModal from './DeleteModal';

const EditGroup = () => {
    const {
        updateGroup,
        setSelectedGroup,
        selectedGroup,
        activeEditTab,
        situations,
        deleteGroup,
        nodes,
        updateNodes,
    } = useContext(CanvasContext);

    const [title, setTitle] = useState(selectedGroup.title || '');
    const [text, setText] = useState(selectedGroup.text || '');
    const [groupSituations, setGroupSituations] = useState([]);
    const [image, setImage] = useState(selectedGroup.image || null);
    const [audio, setAudio] = useState(selectedGroup.audio || null);
    const [color, setColor] = useState(selectedGroup.color || '#ffffff');

    // Modal control
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [uploadType, setUploadType] = useState('');
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

    //Tabs
    const [activeSubTab, setActiveSubTab] = useState('text');


    useEffect(() => {
        setTitle(selectedGroup.title || '');
        setText(selectedGroup.text || '');
        setImage(selectedGroup.image || null);
        setAudio(selectedGroup.audio || null);
        setColor(selectedGroup.color || '#ffffff');
    
        const filteredSituations = situations.filter(
            situation => situation.situationGroup && situation.situationGroup._id === selectedGroup._id
        );
        setGroupSituations(filteredSituations);
    }, [selectedGroup, situations]);
    

    const openModal = (type) => {
        setUploadType(type);
        setIsModalOpen(true);
    };
    

    const handleTitleChange = (event) => {
        setTitle(event.target.value);
    };

    const handleTitleBlur = async () => {
        if (title !== selectedGroup.title) {
            try {
                // Update the group title
                await updateGroup({ title: title });
            } catch (error) {
                console.error("Error updating group: ", error);
            }
        }
    };

    const handleEditorChange = (data) => {
        setText(data);
    };

    const handleTextBlur = async () => {
        if (text !== selectedGroup.text) {
            try {
                // Update the group text
                await updateGroup({ text: text });
            } catch (error) {
                console.error("Error updating group text: ", error);
            }
        }
    };

    const handleDeleteGroup = () => {
        setIsDeleteModalOpen(true);  // Open the DeleteModal
    };

    const confirmDeleteGroup = async () => {
        try {
            await deleteGroup(selectedGroup._id, groupSituations);
            setIsDeleteModalOpen(false); // Close modal after deletion
        } catch (error) {
            console.error('Error deleting group:', error);
        }
    };

    const cancelDeleteGroup = () => {
        setIsDeleteModalOpen(false);  // Close the modal without deleting
    };

    const handleColorChange = async (newColor) => {
        setColor(newColor);
        try {
            await updateGroup({ color: newColor });

            // Update nodes that correspond to situations in the group
            const updatedNodes = nodes.map(node =>
                groupSituations.some(situation => situation._id === node.id)
                    ? { ...node, data: { ...node.data, fillColor: newColor } }
                    : node
            );
            await updateNodes(updatedNodes);
        } catch (error) {
            console.error('Error updating group or node colors:', error);
        }
    };
    
    
    return (
        <>
            <EditSidebarTabs editType="group" />
            {activeEditTab === '1' && (
                <>
                    {/* Subtabs */}
                    <div className={textStyles.subTabsContainer}>
                        <div
                            className={`${textStyles.subTab} ${activeSubTab === 'text' ? textStyles.activeSubTab : ''}`}
                            onClick={() => setActiveSubTab('text')}
                        >
                            Text
                        </div>
                        <div
                            className={`${textStyles.subTab} ${activeSubTab === 'media' ? textStyles.activeSubTab : ''}`}
                            onClick={() => setActiveSubTab('media')}
                        >
                            Media
                        </div>
                    </div>

                    <div className={textStyles.textContainer}>
                        {/* Subtab Content */}
                        {activeSubTab === 'text' && (
                            <>
                                <input
                                    type="text"
                                    value={title}
                                    onChange={handleTitleChange}
                                    onBlur={handleTitleBlur}
                                    className={textStyles.titleInput}
                                />
                                <CKEditor
                                    editor={ClassicEditor}
                                    data={text}
                                    config={{
                                        toolbar: {
                                            items: [
                                                'undo',
                                                'redo',
                                                '|',
                                                'heading',
                                                '|',
                                                'bold',
                                                'italic',
                                                '|',
                                                'link',
                                                'uploadImage',
                                                'blockQuote',
                                                '|',
                                                'bulletedList',
                                                'numberedList',
                                                'outdent',
                                                'indent',
                                            ],
                                            shouldNotGroupWhenFull: true,
                                        },
                                        ckfinder: {
                                            uploadUrl: `${process.env.REACT_APP_API_URL_LOCAL}/api/images/upload`,
                                        },
                                        mediaEmbed: {
                                            elementName: 'iframe',
                                        },
                                    }}
                                    onChange={(event, editor) => {
                                        handleEditorChange(editor.getData());
                                    }}
                                    onBlur={handleTextBlur} // Update the text when the editor loses focus
                                />
                            </>
                        )}
                        {activeSubTab === 'media' && (
                            <>
                                {image && (
                                    <div className={textStyles.mediaPreview}>
                                        <img src={image} alt="Uploaded" className={textStyles.imagePreview} />
                                    </div>
                                )}

                                <div className={textStyles.buttonGroup}>
                                    <button
                                        onClick={() => openModal('image')}
                                        className={`${textStyles.buttonBase} ${textStyles.uploadButton}`}
                                    >
                                        {image ? 'Change Image' : 'Upload Image'}
                                    </button>
                                    {image && (
                                        <button
                                            onClick={async () => {
                                                const updatedGroup = await updateGroup({ image: null });
                                                setImage(null); // Update local state
                                                setSelectedGroup(updatedGroup); // Update selected group to the returned group
                                            }}
                                            className={`${textStyles.buttonBase} ${textStyles.removeButton}`}
                                        >
                                            Remove Image
                                        </button>
                                    )}

                                </div>

                                {audio && (
                                    <div className={textStyles.mediaPreview}>
                                        {audio.type && audio.type.startsWith('video') ? (
                                            <video controls className={textStyles.mediaElement}>
                                                <source src={audio} type={audio.type} />
                                                Your browser does not support the video tag.
                                            </video>
                                        ) : (
                                            <audio controls className={textStyles.mediaElement}>
                                                <source src={audio} type={audio.type} />
                                                Your browser does not support the audio element.
                                            </audio>
                                        )}
                                    </div>
                                )}

                                <div className={textStyles.buttonGroup}>
                                    <button
                                        onClick={() => openModal('audiovideo')}
                                        className={`${textStyles.buttonBase} ${textStyles.uploadButton}`}
                                    >
                                        {audio ? (audio.type && audio.type.startsWith('video') ? 'Change Video' : 'Change Audio') : 'Upload Audio/Video'}
                                    </button>
                                    {audio && (
                                        <button
                                            onClick={async () => {
                                                const updatedGroup = await updateGroup({ audio: null });
                                                setAudio(null); // Update local state
                                                setSelectedGroup(updatedGroup);
                                            }}
                                            className={`${textStyles.buttonBase} ${textStyles.removeButton}`}
                                        >
                                            {audio.type && audio.type.startsWith('video') ? 'Remove Video' : 'Remove Audio'}
                                        </button>
                                    )}
                                </div>
                            </>
                        )}
                    </div>
                </>
            )}

            {activeEditTab === '2' && (
                <SituationsList situations={groupSituations} />
            )}

            {activeEditTab === '3' && (
                <div className={settingsStyles.settingsContainer}>
                    {/* Color Picker */}
                    <div className={settingsStyles.colorPickerContainer}>
                        <label htmlFor="groupColor">Edit Color</label>
                        <input
                            type="color"
                            id="groupColor"
                            value={color}
                            onChange={(e) => setColor(e.target.value)}
                            onBlur={() => handleColorChange(color)}
                            className={settingsStyles.colorPicker}
                        />
                    </div>

                    {/* Delete Button */}
                    <button onClick={handleDeleteGroup} className={settingsStyles.deleteButton}>
                        Delete Group
                    </button>
                </div>
            )}


            {/** Modals */}
            {isModalOpen && (
                <UploadModal uploadType={uploadType} setIsModalOpen={setIsModalOpen} />
            )}
            {isDeleteModalOpen && (
                <DeleteModal
                    onConfirm={confirmDeleteGroup}
                    onCancel={cancelDeleteGroup}
                    type={'Group'}
                />
            )}
        </>
    );
};

export default EditGroup;
